import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import GetHelpScreen from 'components/screens/GetHelpScreen/GetHelpScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <GetHelpScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query GetHelp {
    strapiGetHelp {
      Contact {
        Name
        Number
      }
    }
  }
`;
