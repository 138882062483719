import React from 'react';
import { Helmet } from 'react-helmet';

import Contact from 'components/elements/Contact/Contact';


const GetHelpScreen = ({data}) => {

  const contacts = data.strapiGetHelp.Contact.map(
    (contact, contactIndex) => {
      return (<Contact
        key={`${contact.Name}_${contactIndex}`}
        name={contact.Name}
        phoneNumber={contact.Number}
        />);
    });

  return (
    <>
      <Helmet
          title="Get Help"
          />
      <div className="px-12 max-w-lg m-auto md:max-w-lg md:px-6 lg:max-w-lg lg:px-8">
        <div className='text-center md:text-left mt-6 mb-16'>
          <h1 className='text-4xl font-bold font-heading uppercase'>Get Help</h1>
        </div>
        
        <div className="grid gap-y-8 md:gap-x-6 md:grid-cols-2">
          {contacts}
        </div>
        
      </div>
      </>
  );
};

// const query = graphql`
//   query {
//     allStrapiBlogPosts {
//       edges {
//         node {
//           strapiId
//           Slug
//           Title
//         }
//       }
//     }
//   }
// `;

export default GetHelpScreen;