import React from 'react';
import PropTypes from 'prop-types';
import './Contact.css';

const Contact = ({name, phoneNumber, ...props}) => {
  
  return (
    <a href={`tel:${phoneNumber}`} {...props} className="cursor-pointer">
      <div className='flex space-x-2 w-60'>
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.12272 0.41592L1.20722 0.33877C1.75849 -0.169846 2.67375 -0.0955537 3.1204 0.493069L4.82279 2.73898C5.17162 3.19902 5.1125 3.82193 4.68226 4.21911L4.57888 4.31626C4.27152 4.59914 4.20157 5.04204 4.42535 5.38778C5.11002 6.4393 6.02312 7.28223 7.16434 7.91372C7.53701 8.12231 8.01709 8.05659 8.32444 7.77371L8.42813 7.67655C8.85837 7.27938 9.535 7.22509 10.0333 7.54797L12.4653 9.11954C13.1038 9.531 13.184 10.3768 12.6327 10.8854L12.5485 10.9626C11.2823 12.1341 9.30781 12.3341 7.79144 11.4569C4.84198 9.74531 2.4413 7.53083 0.5891 4.80773C-0.363309 3.40761 -0.143546 1.58459 1.12272 0.41592Z" fill="#EC1C24"/>
        </svg>

        <div>
          <p className='font-bold uppercase mb-1 leading-none'>{name}</p>
          <p className='contact-number text-gray-600 w-max'>{phoneNumber}</p>
        </div>
      </div>
    </a>
  );
}

Contact.propTypes = {
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
}

Contact.defaultProps = {
  name: null,
  phoneNumber: null,
}

export default Contact;